import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomePageComponent } from './pages/welcome-page/welcome-page/welcome-page.component';
import { CompanyManagementComponent } from './pages/company-management/company-management.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { ErrorPageComponent } from '@/app/pages/error-page/error-page/error-page.component';
import { GuestUserComponent } from '@/app/pages/user-management/guest-user/guest-user.component';
import { MemberUserComponent } from '@/app/pages/user-management/member-user/member-user.component';
import { MethodologyExplanationPageComponent } from './pages/methodology-explanation-page/methodology-explanation-page.component';
import { ScenarioLibraryManagementComponent } from './pages/scenario-library-management/scenario-library-management.component';
import { TechnicalUserGuideComponent } from './pages/technical-user-guide/technical-user-guide.component';
import { UserGuideComponent } from './pages/user-guide/user-guide.component';
import { AllProjectsFilesComponent } from '@/app/pages/all-projects-files/all-projects-files.component';

const appRoutes: Routes = [
  { path: '', component: WelcomePageComponent },
  { path: 'company-management', component: CompanyManagementComponent },
  {
    path: 'user-management',
    component: UserManagementComponent,
    children: [
      {
        path: 'guest-user',
        component: GuestUserComponent,
      },
      {
        path: 'member-user',
        component: MemberUserComponent,
      },
      {
        path: '',
        redirectTo: 'member-user',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'scenario-library-management',
    component: ScenarioLibraryManagementComponent,
  },
  {
    path: 'all-projects-files',
    component: AllProjectsFilesComponent,
  },
  { path: 'explanations', component: MethodologyExplanationPageComponent },
  { path: 'technical-user-guide', component: TechnicalUserGuideComponent },
  { path: 'user-guide', component: UserGuideComponent },
  { path: 'error/:type', component: ErrorPageComponent },
  { path: '**', component: ErrorPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
