export const environment = {
  name: 'uat',
  apiUrl: 'https://api.uat.spice.quantis.solutions',
  whitelistedDomains: ['api.uat.spice.quantis.solutions'],
  production: false,
  enableSentry: true,
};

export const awsconfig = {
  aws_project_region: 'eu-west-1',
  userPoolId: 'eu-west-1_NZ46XovrC',
  userPoolWebClientId: '5ho5fs8k8i82h6m6gjk2r94gm1',
};
