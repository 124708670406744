/* eslint-disable max-len */
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { LoginPageComponent } from './pages/login/login-page/login-page.component';
import { LandingPageComponent } from './pages/landing/landing-page/landing-page.component';
import { WelcomePageComponent } from './pages/welcome-page/welcome-page/welcome-page.component';
import { LoginBoxComponent } from './pages/login/login-box/login-box.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfirmModalComponent } from './pages/confirm-modal/confirm-modal.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InformationModalComponent } from './pages/information-modal/information-modal.component';
import { OpenProjectModalComponent } from './pages/landing/open-project-modal/open-project-modal.component';
import { ProjectModalComponent } from './pages/landing/create-project-modal/project-modal.component';
import { GeneralInfoComponent } from './pages/projects/general-info/general-info.component';
import { ProjectsModule } from './pages/projects/projects.module';
import { TranslationPipe } from './intl/translation-pipe';
import { PaginationBarComponent } from './pages/pagination-bar/pagination-bar.component';
import { ValidationMessagesComponent } from './services/formValidationService/validation-message.component';
import { PackagingComponentComponent } from './pages/projects/packaging-component/packaging-component.component';
import { GuestAccessBoxComponent } from './pages/login/guest-access-box/guest-access-box.component';
import { SidebarComponent } from './pages/sidebar/sidebar.component';
import { SidebarFooterComponent } from './pages/sidebar/sidebar-footer.component';
import { SidebarUserMgmtComponent } from './pages/sidebar/sidebar-user-mgmt.component';
import { SidebarSingleUseComponent } from './pages/sidebar/sidebar-single-use.component';
import { EmailFormModalComponent } from 'src/app/pages/email-form-modal/email-form-modal.component';
import { CompanyManagementComponent } from './pages/company-management/company-management.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { CreateCompanyModalComponent } from './pages/company-management/create-company-modal/create-company-modal.component';
import { UserManagementModalComponent } from './pages/user-management/user-management-modal/user-management-modal.component';
import { ErrorPageComponent } from './pages/error-page/error-page/error-page.component';
import { CreateMaterialModalComponent } from '@/app/pages/projects/packaging-component/create-material-modal/create-material-modal.component';
import { EmailFormForgotPasswordComponent } from '@/app/pages/email-form-modal/email-form-forgot-password/email-form-forgot-password';
import { EmailFormForgotPasswordValidationComponent } from '@/app/pages/email-form-modal/email-form-forgot-password-validation/email-form-forgot-password-validation';
import { NewPasswordValidationComponent } from '@/app/pages/login/new-password-validation-component/new-password-validation.component';
import { SpinnerComponent } from '@/app/component/spinner/spinner.component';
import { DeleteUserModalComponent } from '@/app/pages/user-management/delete-user-modal/delete-user-modal.component';
import { ContainerSidebarComponent } from '@/app/pages/container-sidebar/container-sidebar.component';

import { NgbdSortableHeaderDirective } from '@/app/directive/sortable.directive';
import { DisableControlDirective } from '@/app/directive/disable.directive';

import {
  AmplifyAngularModule,
  AmplifyModules,
  AmplifyService,
} from 'aws-amplify-angular';
import Auth from '@aws-amplify/auth';
import { CreateFinishingProcessModalComponent } from '@/app/pages/projects/packaging-component/create-finishing-process-modal/create-finishing-process-modal.component';
import { FinishingProcessRowComponent } from '@/app/pages/projects/packaging-component/finishing-process-row/finishing-process-row.component';
import { GlobalInterceptor } from '@/app/api/interceptors/global-interceptor';
import { MaterialRowComponent } from '@/app/pages/projects/packaging-component/material-row/material-row.component';
import { ProjectPageComponent } from '@/app/pages/projects/project-page.component';

import { environment } from '@/environments/environment';
import { GuestUserComponent } from './pages/user-management/guest-user/guest-user.component';
import { MemberUserComponent } from './pages/user-management/member-user/member-user.component';
import { AssessComponent } from './pages/projects/assess/assess.component';
import { SidebarAssessOneProjectComponent } from './pages/sidebar/sidebar-assess-one-project/sidebar-assess-one-project.component';
import { ToastsContainerComponent } from '@/app/component/toast-container/toast-container';
import { ProjectLockService } from './services/project-lock.service';
import { LoginService } from './services/login/login.service';
import { ProjectsSelectionComponent } from './pages/project-comparison/projects-selection/projects-selection.component';
import { ProjectsAssessmentComponent } from './pages/project-comparison/projects-assessment/projects-assessment.component';
import { ProjectComparisonModule } from './pages/project-comparison/project-comparison.module';
import { ProjectComparisonComponent } from './pages/project-comparison/project-comparison.component';
import { SidebarAssessTwoProjectComponent } from './pages/sidebar/sidebar-assess-two-projects/sidebar-assess-two-project.component';
import { TermsOfUseModalComponent } from './pages/login/terms-of-use-modal/terms-of-use-modal.component';
import { MethodologyExplanationPageComponent } from './pages/methodology-explanation-page/methodology-explanation-page.component';
import { ErrorService } from './services/error.service';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { BreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
import { LoadingService } from './services/loading.service';
import { PopulateComponent } from '@/app/component/populate/populate.component';
import { PopulateModalComponent } from '@/app/component/populate/populate-modal/populate-modal.component';
import { ProjectCollaboratorsModalComponent } from '@/app/pages/projects/share-project-modal/project-collaborators-modal.component';
import { SortToggleComponent } from '@/app/component/sort-toggle/sort-toggle.component';
import { InputCheckboxComponent } from '@/app/component/input-checkbox/input-checkbox.component';
import { ExportScenariosModalComponent } from './pages/projects/export-scenarios-modal/export-scenarios-modal.component';
import { ExportScenarioRowComponent } from './pages/projects/export-scenario-row/export-scenario-row.component';
import { ChangelogModalComponent } from '@/app/component/changelog/changelog-modal.component';
import { ScenarioLibraryManagementComponent } from './pages/scenario-library-management/scenario-library-management.component';
import { EditPublishedScenarioModalComponent } from './pages/scenario-library-management/edit-published-scenario-modal/edit-published-scenario-modal.component';
import { ScenarioLibraryComponent } from './pages/scenario-library/scenario-library.component';
import { ToastService } from './services/toast.service';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { BreakdownByMaterialModalComponent } from './pages/projects/assess/breakdown-by-material-modal/breakdown-by-material-modal.component';
import { TechnicalUserGuideComponent } from './pages/technical-user-guide/technical-user-guide.component';
import { UserGuideComponent } from './pages/user-guide/user-guide.component';
import { AllProjectsFilesComponent } from './pages/all-projects-files/all-projects-files.component';
import { BackButtonComponent } from './component/back-button/back-button.component';
import { HumanFileSizePipe } from '@/app/pages/all-projects-files/humanFileSize.pipe';
import { ScenariosForAssessmentComponent } from '@/app/pages/projects/assess/scenarios-for-assessment/scenarios-for-assessment.component';
import { ToFixedPipe } from '@/app/pages/projects/assess/toFixed.pipe';
import { RechargeProjectForAssessmentComponent } from '@/app/pages/projects/assess/recharge-project-for-assessment/recharge-project-for-assessment.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    LandingPageComponent,
    WelcomePageComponent,
    LoginBoxComponent,
    ConfirmModalComponent,
    ProjectModalComponent,
    OpenProjectModalComponent,
    ProjectCollaboratorsModalComponent,
    EmailFormModalComponent,
    InformationModalComponent,
    ProjectPageComponent,
    MemberUserComponent,
    GuestUserComponent,
    GeneralInfoComponent,
    PaginationBarComponent,
    TranslationPipe,
    HumanFileSizePipe,
    ToFixedPipe,
    SidebarComponent,
    SidebarFooterComponent,
    SidebarUserMgmtComponent,
    SidebarSingleUseComponent,
    SidebarAssessOneProjectComponent,
    SidebarAssessTwoProjectComponent,
    GuestAccessBoxComponent,
    CompanyManagementComponent,
    UserManagementComponent,
    CreateCompanyModalComponent,
    UserManagementModalComponent,
    CreateMaterialModalComponent,
    CreateFinishingProcessModalComponent,
    FinishingProcessRowComponent,
    MaterialRowComponent,
    ValidationMessagesComponent,
    PackagingComponentComponent,
    ErrorPageComponent,
    EmailFormForgotPasswordComponent,
    EmailFormForgotPasswordValidationComponent,
    NewPasswordValidationComponent,
    NgbdSortableHeaderDirective,
    DisableControlDirective,
    DeleteUserModalComponent,
    SpinnerComponent,
    ContainerSidebarComponent,
    AssessComponent,
    ScenariosForAssessmentComponent,
    RechargeProjectForAssessmentComponent,
    TermsOfUseModalComponent,
    ProjectComparisonComponent,
    ProjectsSelectionComponent,
    ProjectsAssessmentComponent,
    ToastsContainerComponent,
    MethodologyExplanationPageComponent,
    BreadcrumbComponent,
    BreadcrumbComponent,
    PopulateComponent,
    PopulateModalComponent,
    SortToggleComponent,
    InputCheckboxComponent,
    ExportScenariosModalComponent,
    ExportScenarioRowComponent,
    ChangelogModalComponent,
    ScenarioLibraryManagementComponent,
    EditPublishedScenarioModalComponent,
    ScenarioLibraryComponent,
    BreakdownByMaterialModalComponent,
    TechnicalUserGuideComponent,
    UserGuideComponent,
    AllProjectsFilesComponent,
    BackButtonComponent,
  ],
  imports: [
    BrowserModule,
    ProjectComparisonModule,
    ProjectsModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        allowedDomains: environment.whitelistedDomains,
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    MatRadioModule,
    AmplifyAngularModule,
    NgSelectModule,
  ],
  providers: [
    TranslationPipe,
    HumanFileSizePipe,
    ToFixedPipe,
    {
      provide: AmplifyService,
      useFactory: () => {
        return AmplifyModules({ Auth });
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalInterceptor,
      multi: true,
      deps: [
        LoginService,
        ProjectLockService,
        ErrorService,
        LoadingService,
        ToastService,
      ],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
      useValue: undefined,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function jwtTokenGetter(): Promise<string> {
  // @ts-ignore
  return Auth.currentSession()
    .then((res) => Promise.resolve(res.getIdToken().getJwtToken()))
    .catch((err) => Promise.resolve(null));
}
